.amb {
    width: auto !important;
    height: 40px;
}

.amb-footer {
    width: auto !important;
    height: 30px;
}

#container-fluid-app {
    padding-left: 40px !important;
    padding-right: 40px !important;
    height: auto;
}

#return_button {
    background-color: #ffffff;
    color: #616161;
    border-radius: 30px;
    border-color: #dee2e6;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

#return_button2 {
    background-color: #ffffff;
    color: #616161;
    border-radius: 30px;
    border-color: #dee2e6;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

#taller-subtitle {
    color: #616161;
    font-size: 20px;
}

#taller-title {
    color: #616161;
}

#taller-title-cast {
    color: #616161;
}

#taller-title2 {
    color: #616161;
}

.divider {
    color: #616161;
}

.divider2 {
    color: #616161;
    height: 5px;
    border-top: 3px solid #616161;
}

#recorda {
    font-weight: bold;
    font-size: 18px;
    color: #616161;
}

#recorda-list {
    border-radius: 20px;
    border: 3px solid #e0e0e0;
    height: auto;
    background-color: #fff;
    padding: 15px;
}

#ul-recorda-list {
    color: #616161;
    padding-top: 10px;
}

#taller-title3 {
    color: #616161;
}

#info-user-registrat {
    color: #616161;
    padding-top: 10px;
}

#inscripcio-user-registrat-button {
    background-color: #ffffff;
    color: #616161;
    border-radius: 30px;
    border-color: #dee2e6;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

#inscripcio-user-nou-button {
    background-color: #ffffff;
    color: #616161;
    border-radius: 30px;
    border-color: #dee2e6;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

#inscripcion-user-registrat-button {
    background-color: #ffffff;
    color: #616161;
    border-radius: 30px;
    border-color: #dee2e6;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

#inscripcion-user-nou-button {
    background-color: #ffffff;
    color: #616161;
    border-radius: 30px;
    border-color: #dee2e6;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

#nom {
    margin-left: 20px;
}

.nom {
    margin: 'auto';
}

#cognoms {
    margin-left: 20px;
}

#sexe {
    margin-left: 20px;
}

#any {
    margin-left: 20px;
}

#via {
    margin-left: 20px;
}

#adreça {
    margin-left: 20px;
}

#num {
    margin-left: 20px;
}

#pis {
    margin-left: 20px;
}

#poblacio {
    margin-left: 20px;
}

#cp {
    margin-left: 20px;
}

#telf {
    margin-left: 20px;
}

#mobil {
    margin-left: 20px;
}

#nif {
    margin-left: 20px;
}

#email {
    margin-left: 20px;
}

#contrasenya {
    margin-left: 20px;
}

#r_contrasenya {
    margin-left: 20px;
}

#coneixement {
    margin-left: 20px;
}

#acord {
    color: #616161;
    font-size: 15px;
}

#error_castellano {
    color: red;
}

#error_catalan {
    color: red;
}

#inscripcion_realizada_castellano {
    color: green;
}

#inscripcion_realizada_catalan {
    color: green;
}

#error_usuarioregistrado_castellano {
    color: red;
}

#error_usuarioregistrado_catalan {
    color: red;
}

#error8talleres_usuarioregistrado_castellano {
    color: red;
}

#error2mismostalleres_usuarioregistrado_castellano {
    color: red;
}

#error8talleres_usuarioregistrado_catalan {
    color: red;
}

#error2mismostalleres_usuarioregistrado_catalan {
    color: red;
}

#success2mismostalleres_usuarioregistrado_castellano {
    color: green;
}

#success2mismostalleres_usuarioregistrado_catalan {
    color: green;
}

.li-footer {
    display: inline-block;
    padding-right: 10px;
}

.ul-footer .li-footer a {
    text-decoration: none;
    color: #827870;
    font-size: 0.875rem;
}

.ul-footer .li-footer a:hover {
    text-decoration: underline;
    color: #827870;
    font-size: 0.875rem;
}

#footer-copy {
    color: #c1bcb8;
    font-size: 0.875rem;
}

.button-anular-inscripcion {
    background-color: '#ffffff';
    color: '#616161';
    border-radius: 30;
    border: '1px solid #dee2e6';
    padding-top: 5;
    padding-bottom: 5;
    padding-left: 30;
    padding-right: 30;
}
.button-anular-inscripcion:hover {
    background-color: '#ffffff';
    color: '#616161';
    border-radius: 30;
    border: '3px solid #dee2e6';
    font-weight: bold;
    padding-top: 5;
    padding-bottom: 5;
    padding-left: 30;
    padding-right: 30;
}
